import React, { useState } from 'react';
import {
    AvatarGroup,
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import {
    getUploadStatus,
    hasOpenReports,
    isUploadMember,
    isVersionMember,
    PostUploadApprovalProps,
    Report,
    Upload,
    UploadApprovalStatusRequest,
    UploadStatus,
    User,
    UserAccountTypes,
    Version,
} from '../../workers/ApiWorker';
import { addDays, convertSimple } from '../../helpers/Date';
import Box from '@mui/material/Box';
import UserAvatar from '../user/UserAvatar';
import { useApi } from '../../contexts/ApiContext';
import RejectUploadModal from '../modal/RejectUploadModal';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import ReportUploadModal from '../modal/ReportUploadModal';
import ViewReportsModal from '../modal/ViewReportsModal';
import { UPDATE_UPLOAD_UPDATE_STATUS_FAILED } from '../../helpers/Messages';
import DeleteUploadModal from '../modal/DeleteUploadModal';
import {
    Delete,
    Download,
    Favorite,
    NewReleases,
    CheckCircle,
    Cancel,
} from '@mui/icons-material';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import {
    TextCardPreviewDescriptionColor,
    TextPrimaryColor,
} from '../../helpers/Themes';
import Tags from './Tags';
import { formatNumberToReadable } from '../../helpers/Numbers';

interface ActionsAreaProps {
    upload: Upload;
    selectedVersion: number;
    isMobile: boolean;
    user?: User;
    setUpload: (upload: Upload) => void;
}

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

const ActionsArea: React.FC<ActionsAreaProps> = ({
    upload,
    selectedVersion,
    isMobile,
    user,
    setUpload,
}) => {
    const apiWorker = useWorker(createApiWorker);

    const { token } = useApi();
    const { theme } = useCustomTheme();

    const [success, setSuccess] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);

    const [activeReportId, setActiveReportId] = useState<string | null>(null);

    const [isReportUploadModalOpen, setIsReportUploadModalOpen] =
        useState<boolean>(false); // State for modal visibility
    const [isViewReportsUploadModalOpen, setIsViewReportsUploadModalOpen] =
        useState<boolean>(false); // State for modal visibility
    const [reports, setReports] = useState<Report[]>([]);

    const [totalDownloads, setTotalDownloads] = useState<number>(
        upload.stats.downloads
    );

    const [isHearted, setIsHearted] = useState<boolean>(
        isUploadMember(upload) ? upload.userHearted : false
    );

    const [hearts, setHearts] = useState<number>(upload.stats.hearts);

    const [isDeleteUploadModalOpen, setIsDeleteUploadModalOpen] =
        useState<boolean>(false);

    const [isDeclineUploadModalOpen, setIsDeclineUploadModalOpen] =
        useState<boolean>(false); // State for modal visibility

    const handleHeartClick = async () => {
        if (token && user) {
            try {
                await apiWorker.postUploadLike(upload.uuid, token);

                setHearts((prevHearts) =>
                    isHearted ? prevHearts - 1 : prevHearts + 1
                );
                setIsHearted(!isHearted);
            } catch (error) {
                console.warn('Could not update like', error);
            }
        }
    };

    const handleDownloadClick = async () => {
        if (
            token &&
            user &&
            upload &&
            isVersionMember(upload.versions[selectedVersion])
        ) {
            try {
                const download = await apiWorker.getUploadVersionLink(
                    {
                        uuid: upload.uuid,
                        versionUuid: upload.versions[selectedVersion].uuid,
                    },
                    token
                );
                window.open(download.data.downloadLink, '_blank');

                if (upload.author.uuid !== user.userProfile.uuid) {
                    setTotalDownloads(totalDownloads + 1);
                }
            } catch (error) {
                console.warn('Could not get download link', error);
            }
        }
    };

    const showViewReportsModal = () => {
        if (token) {
            const getReports = async () => {
                try {
                    const response = await apiWorker.getReportsByUpload(
                        upload.uuid,
                        token
                    );

                    if (response.data) {
                        setReports(response.data);
                    } else {
                        setReports([]);
                    }
                } catch (error) {
                    setReports([]);
                }
            };

            getReports()
                .then(() => {})
                .catch((error) => {
                    console.log('Error fetching reports for upload:', error);
                });

            setActiveReportId(null);
            setSuccess(undefined);
            setError(undefined);
            setIsViewReportsUploadModalOpen(true);
        }
    };

    const showReportModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsReportUploadModalOpen(true);
    };

    const closeReportUploadModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setIsReportUploadModalOpen(false);
    };

    const closeViewReportsModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setActiveReportId(null);
        setSuccess(undefined);
        setError(undefined);
        setIsViewReportsUploadModalOpen(false);
    };

    const showDeleteUploadModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsDeleteUploadModalOpen(true);
    };

    const closeDeleteUploadModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setIsDeleteUploadModalOpen(false);
    };

    const showDeclineUploadModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsDeclineUploadModalOpen(true);
    };

    const closeDeclineUploadModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setIsDeclineUploadModalOpen(false);
    };

    const handleUpdateStatus = async (
        data: PostUploadApprovalProps
    ): Promise<boolean> => {
        setError(undefined);
        setSuccess(undefined);
        let complete = false;
        if (upload && data && token) {
            try {
                const response = await apiWorker.postUploadApproval(
                    upload.uuid,
                    token,
                    data
                );
                console.log('here we go', response.data);
                setUpload(response.data);
                complete = true;
            } catch (error) {
                setError(UPDATE_UPLOAD_UPDATE_STATUS_FAILED);
            }
        }
        return complete;
    };

    return (
        <>
            {getUploadStatus(upload) === UploadStatus.PENDING && (
                <Card
                    sx={{
                        marginBottom: 1,
                        padding: 2,
                        textAlign: 'center',
                        backgroundColor: theme.palette.warning.main,
                    }}
                >
                    <Typography variant="body1" color={TextPrimaryColor}>
                        {UploadStatus.PENDING}
                    </Typography>
                </Card>
            )}

            {getUploadStatus(upload) === UploadStatus.REJECTED &&
                upload.declined && (
                    <Card
                        sx={{
                            marginBottom: 1,
                            padding: 2,
                            textAlign: 'center',
                            backgroundColor: theme.palette.error.main,
                        }}
                    >
                        <Typography variant="body1" color={TextPrimaryColor}>
                            {UploadStatus.REJECTED}
                            <br />
                        </Typography>
                        <Typography variant="body2" color={TextPrimaryColor}>
                            Reason: {upload.declined.reason}
                        </Typography>
                        <Typography variant="body2" color={TextPrimaryColor}>
                            <br />
                            This will be automatically removed on{' '}
                            {addDays(upload.declined.declinedAt, 1)}
                        </Typography>
                    </Card>
                )}

            {token &&
                user &&
                isVersionMember(upload.versions[selectedVersion]) && (
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ marginBottom: 1, padding: 1, fontSize: '28px' }}
                        onClick={handleDownloadClick}
                    >
                        <Download sx={{ fontSize: 28 }} /> DOWNLOAD
                    </Button>
                )}

            {token &&
                user &&
                user.userProfile.accountType !== UserAccountTypes.MEMBER &&
                getUploadStatus(upload) === UploadStatus.PENDING &&
                user.userProfile.uuid !== upload.author.uuid &&
                handleUpdateStatus && (
                    <>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            sx={{
                                textAlign: 'left',
                                padding: 1,
                                marginBottom: 1,
                                justifyContent: 'flex-start',
                                color: 'inherit',
                                textTransform: 'none',
                                gap: 2,
                            }}
                            onClick={() =>
                                handleUpdateStatus({
                                    status: UploadApprovalStatusRequest.APPROVED,
                                })
                            }
                        >
                            <CheckCircle />
                            <Typography variant="body1">APPROVE</Typography>
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            sx={{
                                textAlign: 'left',
                                padding: 1,
                                marginBottom: 1,
                                justifyContent: 'flex-start',
                                color: 'inherit',
                                textTransform: 'none',
                                gap: 2,
                            }}
                            onClick={() => showDeclineUploadModal()}
                        >
                            <Cancel />
                            <Typography variant="body1">REJECT</Typography>
                        </Button>
                        {/* Modal Component */}
                        <RejectUploadModal
                            handleUpdateStatus={handleUpdateStatus}
                            closeModal={closeDeclineUploadModal}
                            setError={setError}
                            error={error}
                            isModalOpen={isDeclineUploadModalOpen}
                            isMobile={isMobile}
                        />
                    </>
                )}

            {token &&
                user &&
                user.userProfile.uuid !== upload.author.uuid &&
                getUploadStatus(upload) === UploadStatus.APPROVED &&
                user.userProfile.accountType === UserAccountTypes.MEMBER && (
                    <>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            sx={{
                                textAlign: 'left',
                                padding: 1,
                                marginBottom: 1,
                                justifyContent: 'flex-start',
                                color: 'inherit',
                                textTransform: 'none',
                                gap: 2,
                            }}
                            onClick={() => showReportModal()}
                        >
                            <NewReleases />
                            <Typography variant="body1">
                                REPORT CONTENT
                            </Typography>
                        </Button>
                        <ReportUploadModal
                            closeModal={closeReportUploadModal}
                            setError={setError}
                            error={error}
                            isModalOpen={isReportUploadModalOpen}
                            isMobile={isMobile}
                            upload={upload}
                            success={success}
                            setSuccess={setSuccess}
                        />
                    </>
                )}

            {hasOpenReports(upload, user) ? (
                <>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{
                            textAlign: 'left',
                            padding: 1,
                            marginBottom: 1,
                            justifyContent: 'flex-start',
                            color: 'inherit',
                            textTransform: 'none',
                            gap: 2,
                        }}
                        onClick={() => showViewReportsModal()}
                    >
                        <NewReleases />
                        <Typography variant="body1">VIEW REPORTS</Typography>
                    </Button>
                    <ViewReportsModal
                        closeModal={closeViewReportsModal}
                        setError={setError}
                        error={error}
                        isModalOpen={isViewReportsUploadModalOpen}
                        isMobile={isMobile}
                        uploadUuid={upload.uuid}
                        reports={reports}
                        success={success}
                        setSuccess={setSuccess}
                        setActiveReportId={setActiveReportId}
                        activeReportId={activeReportId}
                        setReports={setReports}
                        setUpload={setUpload}
                    />
                </>
            ) : null}

            {token &&
            user &&
            getUploadStatus(upload) === UploadStatus.APPROVED &&
            (user.userProfile.uuid === upload.author.uuid ||
                user.userProfile.accountType !== UserAccountTypes.MEMBER) ? (
                <>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{
                            textAlign: 'left',
                            padding: 1,
                            marginBottom: 1,
                            justifyContent: 'flex-start',
                            color: 'inherit',
                            textTransform: 'none',
                            gap: 2,
                        }}
                        onClick={() => showDeleteUploadModal()}
                    >
                        <Delete />
                        <Typography variant="body1">DELETE</Typography>
                    </Button>
                    <DeleteUploadModal
                        closeModal={closeDeleteUploadModal}
                        setError={setError}
                        error={error}
                        isModalOpen={isDeleteUploadModalOpen}
                        isMobile={isMobile}
                        upload={upload}
                    />
                </>
            ) : null}

            {token &&
                user &&
                // <Card sx={{ padding: 2, marginBottom: 1 }}>
                //     <Typography
                //         variant="body1"
                //         color={TextCardPreviewDescriptionColor}
                //     >
                //         SUBSCRIBE (COMING SOON)
                //     </Typography>
                // </Card>
                null}

            {/* Stats - Views & Downloads */}
            <Grid container spacing={1} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <Card sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="h4">
                            {formatNumberToReadable(upload.stats.views)}
                        </Typography>
                        <Typography
                            variant="body2"
                            color={TextCardPreviewDescriptionColor}
                        >
                            VIEWS
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="h4">
                            {formatNumberToReadable(totalDownloads)}
                        </Typography>
                        <Typography
                            variant="body2"
                            color={TextCardPreviewDescriptionColor}
                        >
                            DOWNLOADS
                        </Typography>
                    </Card>
                </Grid>
            </Grid>

            {/* Collaborators Section */}
            {upload.collaborators && upload.collaborators.length > 0 && (
                <Card sx={{ padding: 2, textAlign: 'center', marginBottom: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 1,
                            marginBottom: 1.5,
                            flexWrap: 'wrap', // Ensures wrapping when out of space
                        }}
                    >
                        <AvatarGroup
                            max={upload.collaborators.length}
                            sx={{ justifyContent: 'center', flexWrap: 'wrap' }} // Ensures the avatars wrap inside the group
                        >
                            {upload.collaborators.map((collaborator, index) => (
                                <UserAvatar
                                    key={index}
                                    backup={collaborator.username}
                                    dimensions={36}
                                    border={true}
                                    userProfile={collaborator}
                                    tooltip={true}
                                />
                            ))}
                        </AvatarGroup>
                    </Box>
                    <Typography
                        variant="body2"
                        color={TextCardPreviewDescriptionColor}
                    >
                        COLLABORATORS
                    </Typography>
                </Card>
            )}

            {/* Games & Likes Section */}
            <Grid container spacing={1} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <Card sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="h4">
                            {upload.games[0].name.toUpperCase()}
                        </Typography>
                        <Typography
                            variant="body2"
                            color={TextCardPreviewDescriptionColor}
                        >
                            GAMES
                        </Typography>
                        {upload.games.length > 1 && (
                            <Tooltip
                                title={
                                    <Box sx={{ padding: 1 }}>
                                        {upload.games
                                            .slice(1)
                                            .map((game, index) => (
                                                <Typography
                                                    key={index}
                                                    variant="body2"
                                                >
                                                    {game.name}
                                                </Typography>
                                            ))}
                                    </Box>
                                }
                                placement="bottom"
                                arrow
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: TextCardPreviewDescriptionColor,
                                        cursor: 'pointer',
                                    }}
                                >
                                    + {upload.games.length - 1} more
                                </Typography>
                            </Tooltip>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card
                        sx={{
                            padding: 2,
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center', // Ensures content is centered properly
                            height: '100%', // Ensures both sections take up the same height
                        }}
                    >
                        <IconButton
                            disabled={
                                !token ||
                                (user &&
                                    user.userProfile.uuid ===
                                        upload.author.uuid)
                            }
                            onClick={handleHeartClick}
                            sx={{
                                padding: 0,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <Favorite
                                sx={{
                                    color: isHearted
                                        ? theme.palette.error.main
                                        : theme.palette.text.primary,
                                }}
                            />
                        </IconButton>
                        <Typography
                            variant="body2"
                            color={TextCardPreviewDescriptionColor}
                        >
                            {formatNumberToReadable(hearts)}
                        </Typography>
                    </Card>
                </Grid>
            </Grid>

            {/* Tags Section */}
            <Card sx={{ padding: 2, textAlign: 'center', marginBottom: 1 }}>
                <Tags upload={upload} />
            </Card>

            {/* Version Section */}
            {/*<Card sx={{ padding: 2, textAlign: 'center' }}>*/}
            {/*    <Typography variant="body2">Version (Coming Soon)</Typography>*/}
            {/*</Card>*/}
        </>
    );
};

export default ActionsArea;
