// Themes.ts
import { createTheme, Theme } from '@mui/material/styles';
import { alpha } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';

export const fadeTimeout = 250;

export const getOverallPadding = (
    isMobile: boolean,
    padding?: number
): number | undefined => {
    return isMobile ? 2 : padding !== undefined ? padding : undefined;
};

const typography = {
    fontFamily: ['Archivo', 'Arial', 'sans-serif'].join(','),
    h1: {
        fontSize: '64px', // Set font size to 12px
        fontWeight: 800, // Set font weight to Extra Bold
    },
    h2: {
        fontSize: '32px',
        fontWeight: 800,
    },
    h3: {
        fontSize: '24px',
        fontWeight: 800,
    },
    h4: {
        fontSize: '20px',
        fontWeight: 800,
    },
    h5: {
        fontSize: '16px',
        fontWeight: 800,
    },
    h6: {
        fontSize: '12px',
        fontWeight: 800,
    },
    body1: {
        fontSize: '16px',
        fontWeight: 400,
    },
    body2: {
        fontSize: '12px',
        fontWeight: 400,
    },
    subtitle2: {
        fontSize: '20px',
        fontWeight: 400,
    },
    caption: {
        fontSize: '12px',
        fontWeight: 800,
    },
};

export interface ThemeType {
    theme: Theme;
    themeType: 'default' | 'custom';
}

const BackgroundDefaultColor = '#171B34';
const BackgroundDefaultLeftColor = '#15181F';
const BackgroundPaperColor = '#21253a';

const BackgroundGradientColor =
    'linear-gradient(95deg, ' +
    BackgroundDefaultLeftColor +
    ' 0%, ' +
    BackgroundDefaultColor +
    ' 59%)'; // Same colors as BackgroundGradientColor
const BackgroundGradientColorAlpha = `linear-gradient(95deg, rgba(21, 24, 31, 0.7) 0%, rgba(23, 27, 52, 0.7) 59%)`;

export const ToolBarColor = '#11141B';

export const WarningMainColor = '#FCA324';

export const TextPrimaryColor = '#FFFFFF';
export const TextSecondaryColor = '#CED4EC';
export const TextDisabledColor = '#707485';
export const TextCardPreviewDescriptionColor = '#989cad';

export const PrimaryColor = '#093FFF';
export const ButtonSecondaryColorHover = '#1C2546';

export const SecondaryMainColor = '#15171F';
export const SecondaryLightColor = '#20294C';

export const IconDisabledColor = 'rgba(255, 255, 255, 0.3)';

export const CardColor = 'rgba(46, 55, 89, 0.15)';

export const GenericHighlightColor = '#293246';

export const DropDownHighlightColor = '#32364a';

export const discoveryBlue = {
    theme: createTheme({
        mixins: {
            MuiDataGrid: {
                // Pinned columns sections
                pinnedBackground: '#ff0000',
                // Headers, and top & bottom fixed rows
                containerBackground: 'transparent',
            },
        },
        typography: typography,
        palette: {
            mode: 'dark',
            primary: {
                main: PrimaryColor,
                light: GenericHighlightColor,
            },
            secondary: {
                main: SecondaryMainColor,
                light: SecondaryLightColor,
                dark: BackgroundGradientColor,
            },
            warning: {
                main: WarningMainColor,
            },
            background: {
                default: BackgroundDefaultColor,
                paper: BackgroundPaperColor,
            },
            text: {
                primary: TextPrimaryColor,
                secondary: TextSecondaryColor,
                disabled: TextDisabledColor,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        height: '100%',
                        background: BackgroundGradientColor,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        overscrollBehavior: 'none',
                        overflowX: 'hidden',
                    },
                    body: {
                        minHeight: '100%',
                        background: BackgroundGradientColor,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        margin: 0,
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        overscrollBehavior: 'none',
                        overflowX: 'hidden',
                    },
                    '#root': {
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: ToolBarColor,
                        color: TextDisabledColor,
                    },
                    arrow: {
                        color: ToolBarColor, // Sets the arrow color to match the tooltip background
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: PrimaryColor, // Default checkbox color (light blue)
                        '&.Mui-checked': {
                            color: PrimaryColor, // Blue for checked state
                        },
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        backgroundImage: 'none',
                        backgroundColor: CardColor,
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: alpha(GenericHighlightColor, 0.15),
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: `1px solid ${alpha(GenericHighlightColor, 0.15)}`,
                        },
                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${TextSecondaryColor}`,
                            },
                        },
                        '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `2px solid ${PrimaryColor}`,
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundImage: 'none', // Remove background image
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        backgroundImage: 'none', // Remove background image
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: TextSecondaryColor, // Input text color
                        '&::placeholder': {
                            color: TextDisabledColor, // Placeholder text color
                            opacity: 1, // Full opacity for placeholder
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none', // Remove default shadow
                        '&:hover': {
                            boxShadow: 'none', // Remove hover shadow
                        },
                        '&.MuiButton-containedSuccess': {
                            color: 'white', // Keep white text for success variant
                        },
                    },
                    containedSecondary: {
                        backgroundColor: CardColor, // Set secondary button background
                        color: TextSecondaryColor + ' !important',
                        '&:hover': {
                            backgroundColor: ButtonSecondaryColorHover, // Hover effect
                            color: TextPrimaryColor + ' !important',
                        },
                    },
                },
            },
        },
    }),
    themeType: 'custom',
};
