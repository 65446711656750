// Messages.ts

export const GENERIC_SUCCESS = 'Success!';
export const GENERIC_ERROR = 'Error!';

export const UPLOAD_FILE_FAILED = 'Sorry, could not upload the selected file.';
export const UPLOAD_IMAGE_FAILED =
    'Sorry, could not upload the selected image.';

export const CREATE_UPLOAD_GET_IMAGE_FAILED =
    'Sorry, an error occurred attempting to get your image to upload.';
export const CREATE_UPLOAD_FAILED = 'Sorry, an error occurred while uploading.';
export const UPDATE_UPLOAD_UPDATE_STATUS_FAILED =
    'Sorry, an error occurred whilst updating the status of the upload.';

export const REJECT_UPLOAD_FAILED =
    'Sorry, an error occurred while rejecting the upload.';

export const CREATE_REPORT_SUCCESS =
    'Report has been submitted. Our staff will now review your report.';
export const CREATE_REPORT_FAILED =
    'Sorry, you can only submit one report per upload.';

export const SUSPEND_USERS_FAILED =
    'Sorry, an error occurred whilst suspending users.';
export const UNSUSPEND_USERS_FAILED =
    'Sorry, an error occurred whilst unsuspending users.';

export const USER_PROFILE_UPDATED_SUCCESS = 'Profile has been updated.';
export const USER_PROFILE_UPDATED_FAILED =
    'Sorry, an error occurred whilst updating your profile.';

export const FOLLOW_USER_FAILED = 'ERROR - FOLLOWING USER';

export const SEND_TO_DISCORD_FAILED =
    'Sorry, an error occurred while sending to Discord.';
export const SEND_TO_DISCORD_SUCCESS = 'Sent to Discord!';

export const CREATE_DIRECT_UPLOAD_CORE_DATA_FAILED =
    'Sorry, could not create the information for the direct upload.';

export const FILE_OVER_500MB_FAILED = 'Selected file was over 500MB!';

export const REPORT_REMOVED_SUCCESS = 'Report has been removed.';
export const REPORT_REMOVED_FAILED = 'Sorry, could not remove the report.';

export const DELETE_UPLOAD_FAILED =
    'Sorry, an error occurred whilst trying to remove the upload.';
