import React from 'react';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import UserActions from '../../user/UserActions';
import { ToolBarColor } from '../../../helpers/Themes';
import { User } from '../../../workers/ApiWorker';
import { useApi } from '../../../contexts/ApiContext';
import {
    CreateUploadsLink,
    GamesLink,
    HomeLink,
    LoginLink,
} from '../../../helpers/Links';
import { BottomNavigationAction } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface BottomProfileBarProps {
    isMobile: boolean;
    user?: User;
}
const BottomProfileBar: React.FC<BottomProfileBarProps> = ({
    isMobile,
    user,
}) => {
    const { token } = useApi();
    const loginLink = LoginLink(isMobile);
    const homeLink = HomeLink(isMobile);
    const createUploadsLink = CreateUploadsLink(isMobile);
    const gameLink = GamesLink(isMobile);
    const navigate = useNavigate();

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 3,
            }}
            elevation={3}
        >
            <BottomNavigation sx={{ backgroundColor: ToolBarColor }}>
                <BottomNavigationAction
                    label={homeLink.name}
                    icon={homeLink.icon}
                    onClick={() => navigate(homeLink.path)}
                />
                <BottomNavigationAction
                    label={gameLink.name}
                    icon={gameLink.icon}
                    onClick={() => navigate(gameLink.path)}
                />
                {token && user ? (
                    <BottomNavigationAction
                        label={createUploadsLink.name}
                        icon={createUploadsLink.icon}
                        onClick={() => navigate(createUploadsLink.path)}
                    />
                ) : null}
                {token && user ? (
                    <UserActions
                        isMobile={isMobile}
                        user={user}
                        paddingRight={2}
                    />
                ) : null}
                {!token && !user ? (
                    <BottomNavigationAction
                        label={loginLink.name}
                        icon={loginLink.icon}
                        onClick={() => navigate(loginLink.path)}
                    />
                ) : null}
            </BottomNavigation>
        </Paper>
    );
};

export default BottomProfileBar;
