import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
    Game,
    getUploadStatus,
    hasOpenReports,
    Upload,
    UploadStatus,
    UploadType,
    User,
} from '../../../workers/ApiWorker';
import { CardContent } from '@mui/material';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import Box from '@mui/material/Box';
import { Block } from '@mui/icons-material';
import { TextCardPreviewDescriptionColor } from '../../../helpers/Themes';
import NoImage from '../../upload/NoImage';

interface PreviewCardProps {
    uuid?: string;
    username?: string;
    title: string;
    description: string;
    image: string | null;
    games: Game[];
    type: UploadType;
    upload?: Upload;
    user?: User;
    handleReuseDetails?: (upload: Upload) => void;
}

const PreviewCard: React.FC<PreviewCardProps> = ({
    uuid,
    username,
    title,
    description,
    image,
    games,
    type,
    upload,
    user,
    handleReuseDetails,
}) => {
    const { theme } = useCustomTheme();
    const navigate = useNavigate(); // Initialize useNavigate
    const [imgError, setImgError] = useState(false);

    const handleClick = () => {
        if (handleReuseDetails && upload) {
            handleReuseDetails(upload);
        } else if (uuid && username) {
            navigate(`/${username}/${uuid}`); // Adjust the path as necessary
        }
    };

    return (
        <Card
            sx={{
                cursor: uuid ? 'pointer' : undefined,
                transition: 'transform 0.2s', // This will allow smooth transformation
                '&:hover': {
                    filter: 'brightness(1.1)', // Apply brightness on hover
                    transition: 'all 0.06s ease', // Apply transition for smooth effect
                },
            }}
            onClick={uuid ? handleClick : undefined}
        >
            {upload ? (
                getUploadStatus(upload) === UploadStatus.PENDING ? (
                    <Box sx={{ position: 'relative' }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                backgroundColor: theme.palette.warning.main,
                                padding: '0px 6px',
                                borderRadius: '4px',
                                zIndex: 10, // Ensure it stays on top
                            }}
                        >
                            <Typography
                                variant="caption"
                                color={theme.palette.text.primary}
                            >
                                {UploadStatus.PENDING}
                            </Typography>
                        </Box>
                    </Box>
                ) : getUploadStatus(upload) === UploadStatus.REJECTED ? (
                    <Box sx={{ position: 'relative' }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                backgroundColor: theme.palette.error.main, // Different color for rejected
                                padding: '0px 6px',
                                borderRadius: '4px',
                                zIndex: 10,
                            }}
                        >
                            <Typography
                                variant="caption"
                                color={theme.palette.text.primary}
                            >
                                {UploadStatus.REJECTED}
                            </Typography>
                        </Box>
                    </Box>
                ) : hasOpenReports(upload, user) ? (
                    <Box sx={{ position: 'relative' }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                backgroundColor: theme.palette.error.main, // Different color for rejected
                                padding: '0px 6px',
                                borderRadius: '4px',
                                zIndex: 10,
                            }}
                        >
                            <Typography
                                variant="caption"
                                color={theme.palette.text.primary}
                            >
                                {UploadStatus.REPORTED}
                            </Typography>
                        </Box>
                    </Box>
                ) : null
            ) : null}

            {!imgError && image ? (
                <>
                    <CardMedia
                        sx={{
                            height: 210,
                            objectPosition:
                                type.name === 'arena' ? 'center' : 'top',
                        }}
                        {...(image && {
                            component: 'img',
                            alt: title,
                            height: '100%',
                            image: image,
                        })}
                        onError={() => setImgError(true)} // Handle broken images
                    />
                </>
            ) : (
                <CardMedia
                    sx={{
                        height: 210, // Set the height you want for the CardMedia
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column', // Arrange items in a column
                            justifyContent: 'center', // Center vertically
                            alignItems: 'center', // Center horizontally
                            height: '100%', // Take full height of CardMedia
                        }}
                    >
                        <NoImage />
                    </Box>
                </CardMedia>
            )}

            <CardContent
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundColor: theme.palette.secondary.main,
                    height: '120px',
                }}
            >
                {/* Background image with blur */}
                {!imgError && image && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: 'full',
                            overflow: 'hidden',
                            backgroundColor: theme.palette.secondary.light,
                        }}
                    >
                        <img
                            src={image} // Use the image prop dynamically
                            alt="blurred background"
                            style={{
                                /*
                                width: '538px',
                                height: '286px',
                                margin: '-45px 25px',
                                filter: 'blur(100px)',

                                 */
                                width: '538px',
                                height: '286px',
                                margin: '-45px 2px',
                                filter: 'blur(20px)',
                                opacity: 0.4,
                            }}
                        />
                    </Box>
                )}

                <div style={{ position: 'relative', zIndex: 2 }}>
                    <Typography
                        textAlign={'left'}
                        variant="caption"
                        sx={{
                            color: theme.palette.warning.main,
                            display: 'block', // Ensure it behaves as a block-level element
                            textAlign: 'left', // Explicitly set text alignment
                            marginBottom: 0, // Reset margin if necessary
                        }}
                    >
                        <>
                            {games.length > 0 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {games[0].name.toUpperCase()}
                                </div>
                            )}
                        </>
                    </Typography>
                    <Typography
                        textAlign={'left'}
                        variant="h5"
                        sx={{ marginBottom: 1, marginTop: 1 }}
                    >
                        {title.toUpperCase()}
                    </Typography>
                    <Typography
                        textAlign={'left'}
                        variant="body2"
                        fontSize={'14px'}
                        color={TextCardPreviewDescriptionColor}
                    >
                        {description}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default PreviewCard;
