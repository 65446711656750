// GameCover.tsx
import React from 'react';
import { CardContent, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Game, Upload } from '../../workers/ApiWorker';
import Tag from '../universal/chips/Tag';
import { useNavigate } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import { TextSecondaryColor } from '../../helpers/Themes';
import Card from '@mui/material/Card';
import { Download, Favorite, Save } from '@mui/icons-material';
import { formatNumberToReadable } from '../../helpers/Numbers';

interface GameCoverProps {
    game: Game;
    handleClick?: (value: string) => void;
}

const GameCover: React.FC<GameCoverProps> = ({ game, handleClick }) => {
    return (
        <Card
            sx={{
                width: '100%',
                height: '100%', // Make all cards take the same height
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                transition: 'transform 0.2s',
                '&:hover': {
                    filter: 'brightness(1.1)',
                    transition: 'all 0.06s ease',
                },
            }}
            onClick={() => {
                if (handleClick) {
                    handleClick(game.uuid);
                }
            }}
        >
            {/* Image Section */}
            {game.image?.main ? (
                <CardMedia
                    component="img"
                    alt={game.name}
                    image={game.image.main}
                    sx={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                        aspectRatio: '3/4', // Ensures consistent aspect ratio
                    }}
                />
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%', // Match the height behavior of images
                        aspectRatio: '3/4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexGrow: 1, // Ensures it stretches like the images
                    }}
                >
                    <Typography variant="caption" color={TextSecondaryColor}>
                        {game.name.toUpperCase()}
                    </Typography>
                </Box>
            )}

            {/* Info Section (Game Name) */}
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1, // Ensures consistent height and allows space to grow
                    justifyContent: 'space-between', // Pushes content to top and bottom
                    textAlign: 'left',
                    paddingLeft: 0.5,
                    paddingRight: 0.5,
                    paddingTop: 0.5,
                    marginBottom: -2.5,
                }}
            >
                {/* Other content can go here (e.g., Game name or description, if you have it) */}

                {/* Stats Row (Downloads, Saves, Likes) */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // Flexbox properties already ensure it's positioned at the bottom
                    }}
                >
                    {/* Downloads */}
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                        <Download
                            fontSize="small"
                            sx={{ color: TextSecondaryColor }}
                        />
                        <Typography
                            variant="caption"
                            color={TextSecondaryColor}
                        >
                            {formatNumberToReadable(3333)}
                        </Typography>
                    </Box>

                    {/* Saves */}
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                        <Save
                            fontSize="small"
                            sx={{ color: TextSecondaryColor }}
                        />
                        <Typography
                            variant="caption"
                            color={TextSecondaryColor}
                        >
                            {formatNumberToReadable(55555)}
                        </Typography>
                    </Box>

                    {/* Likes */}
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                        <Favorite
                            fontSize="small"
                            sx={{ color: TextSecondaryColor }}
                        />
                        <Typography
                            variant="caption"
                            color={TextSecondaryColor}
                        >
                            {formatNumberToReadable(22333)}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default GameCover;
