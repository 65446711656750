// ViewUploadPage.tsx
import React, { useEffect, useState } from 'react';
import { Fade, Grid, Typography } from '@mui/material';
import { Upload, User } from '../workers/ApiWorker';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useApi } from '../contexts/ApiContext';
import Box from '@mui/material/Box';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCustomTheme } from '../contexts/ThemeContext';
import {
    fadeTimeout,
    getOverallPadding,
    ToolBarColor,
} from '../helpers/Themes';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import NoResultsFound from '../components/universal/loader/NoResultsFound';
import UserProfileImage from '../components/user/UserProfileImage';
import Card from '@mui/material/Card';
import ContentArea from '../components/upload/ContentArea';
import ActionsArea from '../components/upload/ActionsArea';
import Socials from '../helpers/Socials';

interface ViewUploadPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
    width?: number;
}

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const ViewUploadPage: React.FC<ViewUploadPageProps> = ({
    isMobile,
    padding,
    user,
    width,
}) => {
    const location = useLocation();

    const { token } = useApi();

    const navigate = useNavigate();

    const { theme } = useCustomTheme();

    const { uuid, username } = useParams<{ uuid: string; username: string }>(); // Extract uuid from URL

    const apiWorker = useWorker(createApiWorker);

    const [loadingUpload, setLoadingUpload] = useState<boolean>(true);

    const [upload, setUpload] = useState<Upload | null>(null);

    const [error, setError] = useState<string | undefined>(undefined);

    const [noResultsFound, setNoResultsFound] = useState<boolean>(false);

    const [selectedVersionIndex, setSelectedVersionIndex] = useState<
        number | null
    >(null);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const options = ['latest'];

    const handleClick = () => {
        if (selectedVersionIndex !== null) {
            console.info(`You clicked ${options[selectedVersionIndex]}`);
        }
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number
    ) => {
        setSelectedVersionIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        const getUpload = async () => {
            if (uuid && username) {
                setLoadingUpload(true);
                try {
                    const response = await apiWorker.getUpload({ uuid }, token);

                    if (response.data.author.username === username) {
                        setLoadingUpload(false);
                        setUpload(response.data);
                        setSelectedVersionIndex(
                            response.data.versions.length - 1
                        );
                    } else {
                        setNoResultsFound(true);
                    }
                } catch (error) {
                    setNoResultsFound(true);
                }
            }
        };
        getUpload()
            .then(() => {})
            .catch((error) => {
                console.log('Error fetching upload:', error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <div>
                <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                    {noResultsFound ? (
                        <NoResultsFound />
                    ) : (
                        !loadingUpload &&
                        upload &&
                        selectedVersionIndex !== null && (
                            <>
                                <Fade in={true} timeout={fadeTimeout}>
                                    <div>
                                        <PageTopSection
                                            isMobile={isMobile}
                                            padding={padding}
                                            hideSeperator={true}
                                            gapSize={'small'}
                                        />
                                        <Box
                                            sx={{
                                                paddingLeft: getOverallPadding(
                                                    isMobile,
                                                    padding
                                                ),
                                                paddingRight: getOverallPadding(
                                                    isMobile,
                                                    padding
                                                ),
                                                marginBottom: isMobile ? 10 : 2,
                                            }}
                                        >
                                            <Card
                                                sx={{
                                                    flexGrow: 1,
                                                    padding: 2,
                                                    marginTop: 2,
                                                    marginBottom: 3,
                                                    textAlign: isMobile
                                                        ? 'center'
                                                        : 'left', // Center text on mobile
                                                    whiteSpace: 'pre-line',
                                                    wordBreak: 'break-word',
                                                    backgroundColor: isMobile
                                                        ? 'transparent'
                                                        : ToolBarColor,
                                                }}
                                            >
                                                {/* Responsive Grid Layout */}
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems={
                                                        isMobile
                                                            ? 'center'
                                                            : 'flex-start'
                                                    } // Center items on mobile, top-align on desktop
                                                    justifyContent={
                                                        isMobile
                                                            ? 'center'
                                                            : 'flex-start'
                                                    } // Center horizontally on mobile
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: isMobile
                                                            ? 'column'
                                                            : 'row', // Stack vertically on mobile
                                                        height: '100%', // Ensure the Grid takes full height of the Card
                                                    }}
                                                >
                                                    {/* Profile Image */}
                                                    <Grid
                                                        item
                                                        xs="auto"
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center', // Center horizontally
                                                            alignItems:
                                                                'center', // Center vertically
                                                        }}
                                                    >
                                                        <UserProfileImage
                                                            loadedUser={
                                                                upload.author
                                                            }
                                                            loadedUserImage={
                                                                upload.author
                                                                    .image.main
                                                            }
                                                            imageSize={'medium'}
                                                        />
                                                    </Grid>

                                                    {/* Upload Name and Description */}
                                                    <Grid
                                                        item
                                                        xs
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                            alignItems: isMobile
                                                                ? 'center'
                                                                : 'flex-start', // Center text on mobile, left-align on desktop
                                                            justifyContent:
                                                                'space-between', // Space out the elements vertically
                                                            height: '100%', // Ensure the Grid takes full height of the Card
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h2"
                                                            color={
                                                                theme.palette
                                                                    .text
                                                                    .secondary
                                                            }
                                                            sx={{
                                                                marginBottom: 1,
                                                            }}
                                                        >
                                                            {upload.name.toUpperCase()}
                                                        </Typography>

                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                marginBottom: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: theme
                                                                        .palette
                                                                        .text
                                                                        .secondary,
                                                                }}
                                                                gutterBottom
                                                            >
                                                                submitted
                                                                by&nbsp;&nbsp;
                                                                <Typography
                                                                    component="span"
                                                                    variant="caption"
                                                                    sx={{
                                                                        color: theme
                                                                            .palette
                                                                            .warning
                                                                            .main,
                                                                        marginTop: 0.2,
                                                                        cursor: 'pointer', // Ensure cursor is set
                                                                        position:
                                                                            'relative', // Add this to handle potential layout issues
                                                                    }}
                                                                    onClick={() => {
                                                                        const userProfileUuid =
                                                                            upload
                                                                                .author
                                                                                .uuid;
                                                                        navigate(
                                                                            `/${upload.author.username}`,
                                                                            {
                                                                                state: {
                                                                                    userProfileUuid,
                                                                                },
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    {upload.author.username.toUpperCase()}
                                                                </Typography>
                                                            </Typography>
                                                        </Box>

                                                        <Typography
                                                            color={
                                                                theme.palette
                                                                    .text
                                                                    .disabled
                                                            }
                                                            sx={{
                                                                marginBottom: 1,
                                                            }}
                                                        >
                                                            {
                                                                upload.miniDescription
                                                            }
                                                        </Typography>

                                                        {(upload.author
                                                            .twitter ||
                                                            upload.author
                                                                .patreon ||
                                                            upload.author
                                                                .facebook ||
                                                            upload.author
                                                                .patreon ||
                                                            upload.author
                                                                .youtube) && (
                                                            <Grid
                                                                sx={{
                                                                    marginTop:
                                                                        isMobile
                                                                            ? 1
                                                                            : 0,
                                                                }}
                                                            >
                                                                <Socials
                                                                    twitter={
                                                                        upload
                                                                            .author
                                                                            .twitter
                                                                    }
                                                                    patreon={
                                                                        upload
                                                                            .author
                                                                            .patreon
                                                                    }
                                                                    facebook={
                                                                        upload
                                                                            .author
                                                                            .facebook
                                                                    }
                                                                    paypal={
                                                                        upload
                                                                            .author
                                                                            .paypal
                                                                    }
                                                                    youtube={
                                                                        upload
                                                                            .author
                                                                            .youtube
                                                                    }
                                                                ></Socials>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Card>

                                            <Grid
                                                container
                                                columnSpacing={
                                                    width && width >= 2140
                                                        ? 25
                                                        : 15
                                                }
                                                sx={{
                                                    marginTop: 10,
                                                }}
                                            >
                                                {/* Left Section - Image (9 columns on XL) */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={
                                                        width && width >= 2140
                                                            ? 9
                                                            : 8
                                                    }
                                                >
                                                    <ContentArea
                                                        upload={upload}
                                                        user={user}
                                                        isMobile={isMobile}
                                                        selectedVersion={
                                                            selectedVersionIndex
                                                        }
                                                    />
                                                </Grid>

                                                {/* Right Section - Content (3 columns on XL) */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={
                                                        width && width >= 2140
                                                            ? 3
                                                            : 4
                                                    }
                                                >
                                                    <ActionsArea
                                                        upload={upload}
                                                        selectedVersion={
                                                            selectedVersionIndex
                                                        }
                                                        isMobile={isMobile}
                                                        user={user}
                                                        setUpload={setUpload}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </div>
                                </Fade>
                            </>
                        )
                    )}
                </Box>
            </div>
        </Fade>
    );
};

export default ViewUploadPage;
