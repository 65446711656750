import React, { useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface SearchBarInputProps {
    isMobile: boolean;
}

const SearchBarInput: React.FC<SearchBarInputProps> = ({ isMobile }) => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState<string>(''); // Use empty string instead of null
    const inputRef = useRef<HTMLInputElement>(null); // Correctly reference the input element

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && inputRef.current) {
            localStorage.removeItem('searchParams');
            const query = searchValue.trim();
            const searchId = new Date().getTime();
            if (query) {
                navigate(
                    `/search?query=${encodeURIComponent(query)}&searchId=${searchId}`,
                    { replace: true }
                );
            }
            setSearchValue(''); // Reset search value
            inputRef.current.blur(); // Blur the input field immediately after pressing Enter
        }
    };

    return (
        <TextField
            id="search"
            size="small"
            variant="outlined"
            placeholder="Search"
            inputRef={inputRef} // Pass the inputRef here to correctly focus/blurring
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={handleKeyDown}
            fullWidth
        />
    );
};

export default SearchBarInput;
