// NoImage.tsx
import React from 'react';
import { Typography } from '@mui/material';
import { Block } from '@mui/icons-material';
import Card from '@mui/material/Card';
import { useCustomTheme } from '../../contexts/ThemeContext';
import Box from '@mui/material/Box';

const NoImage: React.FC = ({}) => {
    const { theme } = useCustomTheme();

    return (
        <Box
            sx={{
                maxHeight: '350px', // Constrain the maximum height
                maxWidth: '100%', // Ensure it adjusts to the container width
                height: 'auto', // Allow height to adjust dynamically
                display: 'flex', // Use flexbox to align content
                flexDirection: 'column',
                justifyContent: 'center', // Center-align content vertically
                alignItems: 'center', // Center-align content horizontally
                borderRadius: '4px', // Same rounded corners as the image
                overflow: 'hidden', // Ensure no content overflows
            }}
        >
            <Block
                sx={{
                    marginTop: 8,
                    fontSize: 60,
                    color: theme.palette.error.main,
                }}
            />
            <Typography
                variant="h2"
                sx={{
                    marginTop: 1,
                    marginBottom: 8,
                }}
            >
                NO IMAGE
            </Typography>
        </Box>
    );
};

export default NoImage;
