// src/components/modal/UnsuspendUsersModal.js

import React from 'react';
import { Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { UnsuspendUserProfileProps, User } from '../../workers/ApiWorker';
import CustomModal from '../universal/modals/CustomModal';
import ErrorAlert from '../universal/alerts/ErrorAlert';
import TextInput from '../universal/inputs/TextInput';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useApi } from '../../contexts/ApiContext';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import Box from '@mui/material/Box';
import { UNSUSPEND_USERS_FAILED } from '../../helpers/Messages';

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

interface UnsuspendUsersModalProps {
    isMobile: boolean;
    isModalOpen: boolean;
    closeModal: (resetForm?: () => void) => void;
    setError: (error: string | undefined) => void; // Updated to match the state setter type
    selectedUsers: User[];
    error?: string;
    setUsers: (users: User[]) => void;
    users: User[];
}

// Define validation schema using Yup
const validationSchema = Yup.object({
    reason: Yup.string().required('Reason is required'),
});

const UnsuspendUsersModal: React.FC<UnsuspendUsersModalProps> = ({
    isMobile,
    isModalOpen,
    setError,
    error,
    selectedUsers,
    closeModal,
    setUsers,
    users,
}) => {
    const apiWorker = useWorker(createApiWorker);
    const { theme } = useCustomTheme();
    const { token } = useApi();

    const handleUnsuspendUsers = async (
        values: { reason: string },
        resetForm: () => void
    ) => {
        if (!token) {
            return;
        }

        const data: UnsuspendUserProfileProps = {
            ...values,
            uuids: selectedUsers.map(
                (selectedUser) => selectedUser.userProfile.uuid
            ),
        };

        try {
            const results = await apiWorker.unsuspendUserProfiles(data, token);

            // Create a new copy of selectedUsers and filter it based on UUIDs in alreadySuspended or failed
            const successfulUnsuspendedUsers = [...selectedUsers].filter(
                (selectedUser) => {
                    const selectedUserUuid = selectedUser.userProfile.uuid;

                    // Check if the UUID is in alreadySuspended or failed lists, and remove it if found
                    const isAlreadyUnsuspended =
                        results.data.alreadyUnsuspended.includes(
                            selectedUserUuid
                        );

                    const isFailed =
                        results.data.failed.includes(selectedUserUuid);
                    return !isAlreadyUnsuspended && !isFailed;
                }
            );

            // Create a new copy of the 'users' array
            let newUsers = [...users]; // Assuming 'users' is the array of all users

            newUsers.forEach((newUser) => {
                const isUnuspended = successfulUnsuspendedUsers.some(
                    (unsuspendedUser) =>
                        unsuspendedUser.userProfile.uuid ===
                        newUser.userProfile.uuid
                );

                if (isUnuspended) {
                    // Update the accountStatus to 'suspended'
                    newUser.userProfile.accountStatus = 'active';
                }
            });

            setUsers(newUsers);
            closeModal(resetForm);
        } catch (error) {
            setError(UNSUSPEND_USERS_FAILED);
        }
    };

    return (
        <Formik
            initialValues={{
                reason: '',
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
                await handleUnsuspendUsers(values, resetForm);
            }}
        >
            {({
                errors,
                touched,
                values,
                handleChange,
                isSubmitting,
                handleBlur,
                isValid,
                handleSubmit,
                resetForm,
            }) => (
                <CustomModal
                    isOpen={isModalOpen}
                    onClose={() => closeModal(resetForm)}
                    confirmLabel="Unsuspend"
                    onConfirm={handleSubmit}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    isMobile={isMobile}
                >
                    {/* Modal content passed directly as children */}
                    <Typography
                        variant="h3"
                        color={theme.palette.text.secondary}
                    >
                        UNSUSPEND USERS
                    </Typography>
                    <Typography
                        variant="body2"
                        color={theme.palette.warning.main}
                        sx={{ marginBottom: 3 }}
                    >
                        Users who are already active will be ignored.
                    </Typography>
                    {error && <ErrorAlert message={error}></ErrorAlert>}
                    {selectedUsers.length > 0 && (
                        <>
                            <Typography sx={{ mt: 2 }} variant="body1">
                                Are you sure you want to unsuspend these users?
                            </Typography>
                            <Box
                                sx={{
                                    maxHeight: 200,
                                    overflowY: 'auto', // Enable vertical scrolling
                                }}
                            >
                                <ul>
                                    {selectedUsers.map((x) => (
                                        <li key={x.uuid}>
                                            {x.userProfile.username}{' '}
                                            {x.userProfile.accountStatus ===
                                                'active' && (
                                                <span
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                >
                                                    (Already Active)
                                                </span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                            <Form>
                                <TextInput
                                    id={'reason'}
                                    label={'Reason'}
                                    value={values.reason}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    hasSubmitted={isSubmitting}
                                    touched={touched.reason}
                                    errors={errors.reason}
                                    enableLabel={true}
                                />
                            </Form>
                        </>
                    )}
                </CustomModal>
            )}
        </Formik>
    );
};

export default UnsuspendUsersModal;
