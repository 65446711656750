// GamesPage.tsx
import React, { useEffect, useState } from 'react';
import {
    fadeTimeout,
    getOverallPadding,
    TextCardPreviewDescriptionColor,
    TextSecondaryColor,
} from '../helpers/Themes';
import { Fade, Grid, Box, Typography, CardContent } from '@mui/material';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { Game, User } from '../workers/ApiWorker';
import NoResultsFound from '../components/universal/loader/NoResultsFound';
import LoadingPreviewCard from '../components/universal/cards/LoadingPreviewCard';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { useNavigate } from 'react-router-dom';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import NoImage from '../components/upload/NoImage';
import GameCover from '../components/game/GameCover';
import GameCoverLoading from '../components/game/GameCoverLoading';
import { wait } from '@testing-library/user-event/dist/utils';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

interface GamesPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const GamesPage: React.FC<GamesPageProps> = ({ isMobile, padding, user }) => {
    const apiWorker = useWorker(createApiWorker);
    const navigate = useNavigate();

    const [loadingResults, setLoadingResults] = useState<boolean>(true);
    const [games, setGames] = useState<Game[] | null>(null);

    useEffect(() => {
        const fetchGames = async () => {
            try {
                setLoadingResults(true);
                const response = await apiWorker.getGames();

                await wait(500);
                // Sort games by name alphabetically
                const sortedGames = response.data.sort((a: Game, b: Game) =>
                    a.name.localeCompare(b.name)
                );

                setGames(sortedGames);
            } catch (error) {
                console.error('Error fetching games:', error);
                setGames(null);
            } finally {
                setLoadingResults(false);
            }
        };

        fetchGames();
    }, [apiWorker]);

    const handleClickValue = (searchValue: string) => {
        localStorage.removeItem('searchParams');
        const query = searchValue.trim();
        const searchId = new Date().getTime();
        if (query) {
            navigate(
                `/search?games=${encodeURIComponent(query)}&searchId=${searchId}`,
                { replace: true }
            );
        }
    };

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box sx={{ position: 'relative', overflow: 'hidden', padding: 2 }}>
                <Box
                    sx={{
                        paddingLeft: getOverallPadding(isMobile, padding),
                        paddingRight: getOverallPadding(isMobile, padding),
                    }}
                >
                    <PageTopSection
                        isMobile={isMobile}
                        padding={padding}
                        hideSeperator={true}
                        gapSize={'small'}
                    />
                    {loadingResults ? (
                        <Fade in={true} timeout={fadeTimeout}>
                            <Grid
                                container
                                spacing={2}
                                sx={{ marginBottom: 10 }}
                            >
                                {Array.from({
                                    length: 100,
                                }).map((_, index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        xl={2}
                                        key={index}
                                        sx={{ display: 'flex' }} // Ensures all grid items stretch equally
                                    >
                                        <GameCoverLoading />
                                    </Grid>
                                ))}
                            </Grid>
                        </Fade>
                    ) : games && games.length > 0 ? (
                        <Grid container spacing={2} sx={{ marginBottom: 10 }}>
                            {games.map((game) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                    key={game.uuid}
                                    sx={{ display: 'flex' }} // Ensures all grid items stretch equally
                                >
                                    <Fade in={true} timeout={fadeTimeout}>
                                        <div
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                width: '100%',
                                                height: '100%',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <GameCover
                                                game={game}
                                                handleClick={handleClickValue}
                                            />
                                        </div>
                                    </Fade>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <NoResultsFound />
                    )}
                </Box>
            </Box>
        </Fade>
    );
};

export default GamesPage;
