// UserAvatar.tsx
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { UserProfile } from '../../workers/ApiWorker';
import { useNavigate } from 'react-router-dom';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { ToolBarColor } from '../../helpers/Themes';
import { Tooltip } from '@mui/material'; // Assuming you're using MUI's theme

interface UserAvatarProps {
    backup: string;
    dimensions?: number;
    marginRight?: number;
    padding?: number;
    border?: boolean;
    total?: boolean;
    userProfile?: UserProfile;
    tooltip?: boolean;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
    backup,
    dimensions,
    marginRight,
    padding,
    border,
    total,
    userProfile,
    tooltip,
}) => {
    const { theme } = useCustomTheme();

    const navigate = useNavigate();

    return (
        <Tooltip
            placement="top"
            arrow
            title={tooltip && userProfile ? userProfile.username : null}
        >
            <Avatar
                src={userProfile?.image.main || undefined}
                sx={{
                    width: dimensions ? dimensions : undefined, // If dimensions provided, use it, else default to 36
                    height: dimensions ? dimensions : undefined, // Same for height
                    border: border
                        ? `2px solid ${theme.palette.primary.main}`
                        : undefined,
                    padding: padding ? `${padding}px` : undefined, // If padding is provided, apply it
                    mr: marginRight ? marginRight : undefined, // If marginRight is provided, apply it
                    color: theme.palette.text.primary,
                    cursor: userProfile ? 'pointer' : undefined, // Show pointer cursor when clickable
                    backgroundColor: userProfile?.image.main
                        ? ToolBarColor
                        : theme.palette.text.disabled,
                }}
                onClick={() => {
                    if (userProfile) {
                        const userProfileUuid = userProfile.uuid;
                        navigate(`/${userProfile.username}`, {
                            state: { userProfileUuid },
                        }); // Navigate only if userProfile exists
                    }
                }}
            >
                {total
                    ? backup
                    : !userProfile?.image.main
                      ? backup.slice(0, 1).toUpperCase()
                      : null}
            </Avatar>
        </Tooltip>
    );
};

export default UserAvatar;
