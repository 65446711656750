import React, { useEffect, useState } from 'react';
import {
    alpha,
    Badge,
    Divider,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import { NotificationsLink } from '../../../helpers/Links';
import TopAppBarItemButtonLink from '../../navigation/navbar/TopAppBarItemButtonLink';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useApi } from '../../../contexts/ApiContext';
import { Notification } from '../../../workers/ApiWorker';
import NotificationBody from '../../notification/NotificationBody';
import { extractNotificationData } from '../../../helpers/Notifiations';

interface NotificationActionsProps {
    notificationCount: number;
    handleNotificationCount: (value: number) => void;
    isMobile: boolean;
    borderLeft?: boolean;
    borderRight?: boolean;
}

const createApiWorker = createWorkerFactory(
    () => import('../../../workers/ApiWorker')
);

const NotificationActions: React.FC<NotificationActionsProps> = ({
    notificationCount,
    handleNotificationCount,
    isMobile,
    borderRight,
    borderLeft,
}) => {
    const apiWorker = useWorker(createApiWorker);
    const { token } = useApi();

    const navigate = useNavigate();
    const location = useLocation();
    const { theme } = useCustomTheme();
    const notificationsLink = NotificationsLink(isMobile);
    const maxNotifications = 5;

    const [showMenu, setShowMenu] = useState<boolean>(false);

    const [usersNotifications, setUsersNotifications] = useState<
        Notification[]
    >([]);

    const handleViewNotificationMenu = () => {
        setShowMenu(true);
    };

    const handleCloseNotificationMenu = (navigateLink?: string) => {
        setShowMenu(false);
        if (navigateLink) {
            navigate(navigateLink);
        }
    };

    const handleSelectedNotification = async (
        notification: Notification,
        navigateLink?: string
    ) => {
        if (token) {
            try {
                await apiWorker.deleteNotification(notification.uuid, token);
                const newCount = notificationCount - 1;
                handleNotificationCount(notificationCount - 1);
                setUsersNotifications((prevNotifications) =>
                    prevNotifications.filter(
                        (n) => n.uuid !== notification.uuid
                    )
                );
                if (newCount < 1) {
                    handleCloseNotificationMenu();
                }
                handleCloseNotificationMenu(navigateLink);
            } catch (error) {
                console.warn('Could not mark notification as read', error);
            }
        }
    };

    useEffect(() => {
        const getNotificationsForUser = async () => {
            if (token) {
                const response = await apiWorker.getNotifications(token);
                setUsersNotifications(response.data);
            }
        };

        getNotificationsForUser()
            .then(() => {})
            .catch((error) => {
                console.log('Error fetching notifications for users:', error);
            });
    }, [location]);

    // Use conditional rendering outside of the return statement
    if (!isMobile) {
        return (
            <>
                {borderLeft ? (
                    <Box
                        sx={{
                            width: '0',
                            padding: '0',
                            height: `64px`, // Match AppBar height
                            minWidth: '0', // Adjust button width to content
                            borderRadius: 0, // Remove rounded borders
                            textTransform: 'none', // Disable uppercase text
                            borderLeft: borderLeft
                                ? `1px solid ${alpha(theme.palette.primary.light, 0.3)}`
                                : undefined,
                        }}
                    ></Box>
                ) : null}
                <IconButton
                    disabled={
                        notificationCount === 0 ||
                        location.pathname === '/notifications'
                    }
                    id="notification-button"
                    aria-controls={showMenu ? 'notification-menu' : undefined}
                    aria-label="delete"
                    sx={{
                        marginRight: isMobile ? 0 : 1.35,
                        marginLeft: isMobile ? 0 : 1.38,
                    }}
                    size="small"
                    onClick={() => {
                        handleViewNotificationMenu();
                    }}
                >
                    <Badge
                        color="error"
                        badgeContent={
                            location.pathname === '/notifications'
                                ? 0
                                : notificationCount
                        }
                        max={maxNotifications}
                    >
                        {React.cloneElement(notificationsLink.icon)}
                    </Badge>
                </IconButton>
                <Menu
                    onClose={() => {
                        handleCloseNotificationMenu();
                    }}
                    open={showMenu}
                    id="notification-menu"
                    anchorEl={document.getElementById('notification-button')} // Set anchorEl to the IconButton
                    MenuListProps={{
                        'aria-labelledby': 'notification-button',
                    }}
                >
                    <MenuItem disabled>
                        <Typography variant="subtitle1">
                            Notifications
                        </Typography>
                    </MenuItem>

                    {/* Divider between the header and notifications */}
                    <Divider />
                    {usersNotifications.slice(0, 5).map((notification) => {
                        const extractedData =
                            extractNotificationData(notification);

                        // Return the MenuItem with the additional content
                        return (
                            <MenuItem
                                key={notification.uuid}
                                sx={{
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                }}
                                onClick={async () => {
                                    await handleSelectedNotification(
                                        notification,
                                        extractedData.link
                                    );
                                }}
                            >
                                <NotificationBody
                                    additionalContent={
                                        extractedData.additionalContent
                                    }
                                    createdAt={notification.createdAt}
                                ></NotificationBody>
                            </MenuItem>
                        );
                    })}
                    {notificationCount > maxNotifications && (
                        <MenuItem
                            onClick={() => {
                                handleCloseNotificationMenu(
                                    notificationsLink.path
                                );
                            }}
                        >
                            View All
                        </MenuItem>
                    )}
                </Menu>
                {borderRight ? (
                    <Box
                        sx={{
                            width: '0',
                            padding: '0',
                            height: `64px`, // Match AppBar height
                            minWidth: '0', // Adjust button width to content
                            borderRadius: 0, // Remove rounded borders
                            textTransform: 'none', // Disable uppercase text
                            borderRight: borderRight
                                ? `1px solid ${alpha(theme.palette.primary.light, 0.3)}`
                                : undefined,
                        }}
                    ></Box>
                ) : null}
            </>
        );
    }

    return (
        <TopAppBarItemButtonLink
            disabled={
                notificationCount === 0 ||
                location.pathname === '/notifications'
            }
            icon={
                <Badge
                    color="error"
                    badgeContent={
                        location.pathname === '/notifications'
                            ? 0
                            : notificationCount
                    }
                    max={maxNotifications}
                    sx={{
                        marginRight: isMobile ? 0 : 0.5,
                        marginLeft: isMobile ? 0 : 0.5,
                    }}
                >
                    {React.cloneElement(notificationsLink.icon)}
                </Badge>
            }
            link={notificationsLink.path}
            borderRight={borderRight}
            borderLeft={borderLeft}
        />
    );
};

export default NotificationActions;
