import React, { useState } from 'react';
import { Fade } from '@mui/material';
import { Upload, User } from '../../workers/ApiWorker';
import Box from '@mui/material/Box';
import DescriptionArea from './DescriptionArea';
import NoImage from './NoImage';
import { convertYTLink, getThumbnail } from '../../helpers/YouTube';
import { fadeTimeout } from '../../helpers/Themes';
import { useCustomTheme } from '../../contexts/ThemeContext';

interface ContentAreaProps {
    upload: Upload;
    selectedVersion: number;
    isMobile: boolean;
    user?: User;
}

const ContentArea: React.FC<ContentAreaProps> = ({
    upload,
    selectedVersion,
    isMobile,
    user,
}) => {
    const { theme } = useCustomTheme();
    const [imgError, setImgError] = useState(false);
    const [selectedContent, setSelectedContent] = useState<'image' | 'video'>(
        'image'
    );

    return (
        <>
            {/* Main Content */}
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    paddingTop: '56.25%', // Maintain 16:9 aspect ratio for consistency
                    overflow: 'hidden',
                }}
            >
                {upload.images?.main && (
                    <Fade
                        in={selectedContent === 'image' && !imgError}
                        timeout={fadeTimeout}
                    >
                        <img
                            src={upload.images.main}
                            alt="Upload Preview"
                            onError={() => setImgError(true)}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: 4,
                                opacity: selectedContent === 'image' ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out',
                            }}
                        />
                    </Fade>
                )}
                {/* If there is an error or no image, show a fallback */}
                {(imgError || !upload.images?.main) &&
                    selectedContent === 'image' && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                borderRadius: 4,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <NoImage />
                        </Box>
                    )}
                {upload.videoUrl && (
                    <Fade
                        in={selectedContent === 'video'}
                        timeout={fadeTimeout}
                    >
                        <iframe
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                borderRadius: '4px',
                                opacity: selectedContent === 'video' ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out',
                            }}
                            src={convertYTLink(upload.videoUrl)}
                            title="YouTube video"
                        ></iframe>
                    </Fade>
                )}
            </Box>

            {/* Thumbnails for selection */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 1,
                    marginTop: 2,
                }}
            >
                {upload.images?.main && !imgError && (
                    <img
                        src={upload.images.main}
                        alt="Thumbnail Image"
                        onClick={() => setSelectedContent('image')}
                        onError={() => setImgError(true)}
                        style={{
                            width: 80,
                            height: 50,
                            cursor: 'pointer',
                            borderRadius: 4,
                            border:
                                selectedContent === 'image'
                                    ? `1px solid ${theme.palette.warning.main}` // Use the warning color from the theme
                                    : 'none',
                        }}
                    />
                )}
                {upload.videoUrl && (
                    <img
                        src={getThumbnail(upload.videoUrl)} // Replace with a real thumbnail or a generated one
                        alt="Thumbnail Video"
                        onClick={() => setSelectedContent('video')}
                        style={{
                            width: 80,
                            height: 50,
                            cursor: 'pointer',
                            borderRadius: 4,
                            border:
                                selectedContent === 'video'
                                    ? `1px solid ${theme.palette.warning.main}` // Use the warning color from the theme
                                    : 'none',
                        }}
                    />
                )}
            </Box>

            {/* Description */}
            <Box sx={{ textAlign: 'left' }}>
                <DescriptionArea upload={upload} />
            </Box>
        </>
    );
};

export default ContentArea;
