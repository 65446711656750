// NotificationsPage.tsx
import React, { useEffect, useState } from 'react';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import { CardContent, Divider, Fade, Typography } from '@mui/material';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { Notification, User } from '../workers/ApiWorker';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../contexts/ApiContext';
import { extractNotificationData } from '../helpers/Notifiations';
import NotificationBody from '../components/notification/NotificationBody';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import Card from '@mui/material/Card';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

interface NotificationsPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const NotificationsPage: React.FC<NotificationsPageProps> = ({
    isMobile,
    padding,
    user,
}) => {
    const location = useLocation();
    const apiWorker = useWorker(createApiWorker);
    const { token } = useApi();
    const navigate = useNavigate();

    const [loadingResults, setLoadingResults] = useState<boolean>(true);
    const [usersNotifications, setUsersNotifications] = useState<
        Notification[]
    >([]);

    useEffect(() => {
        const getNotifications = async () => {
            if (token) {
                setLoadingResults(true);
                try {
                    const response = await apiWorker.getNotifications(token);

                    setUsersNotifications(response.data);
                } catch (error) {
                    console.log('error getting notifications');
                }
                setLoadingResults(false);
            }
        };

        getNotifications()
            .then(() => {})
            .catch((error) => {
                console.log('Error fetching notifications:', error);
            });
    }, [location]);

    const handleMarkNotificationAsRead = async (
        notificationUuid: string,
        link?: string
    ) => {
        if (token) {
            try {
                await apiWorker.deleteNotification(notificationUuid, token);

                setUsersNotifications((prevNotifications) =>
                    prevNotifications.filter((n) => n.uuid !== notificationUuid)
                );

                if (link) {
                    navigate(link);
                }
            } catch (error) {
                console.warn('Could not mark notification as read', error);
            }
        }
    };

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box sx={{ position: 'relative', overflow: 'hidden', padding: 2 }}>
                <Box
                    sx={{
                        paddingLeft: getOverallPadding(isMobile, padding),
                        paddingRight: getOverallPadding(isMobile, padding),
                    }}
                >
                    <PageTopSection
                        isMobile={isMobile}
                        padding={padding}
                        hideSeperator={true}
                        gapSize={'small'}
                    />
                    {!loadingResults ? (
                        <Fade in={true} timeout={fadeTimeout}>
                            <div>
                                <Card>
                                    <CardContent>
                                        {usersNotifications.length > 0 ? (
                                            usersNotifications.map(
                                                (userNotification, index) => {
                                                    // Extract relevant notification data
                                                    const extractedData =
                                                        extractNotificationData(
                                                            userNotification
                                                        );

                                                    return (
                                                        <>
                                                            <Box
                                                                sx={{
                                                                    marginBottom:
                                                                        index <
                                                                        usersNotifications.length -
                                                                            1
                                                                            ? 3
                                                                            : 1,
                                                                    marginTop: 3,
                                                                }}
                                                            >
                                                                <NotificationBody
                                                                    key={index}
                                                                    additionalContent={
                                                                        extractedData.additionalContent
                                                                    }
                                                                    createdAt={
                                                                        userNotification.createdAt
                                                                    }
                                                                    isPage={
                                                                        true
                                                                    }
                                                                    notification={
                                                                        userNotification
                                                                    }
                                                                    handleMarkNotificationAsRead={
                                                                        handleMarkNotificationAsRead
                                                                    }
                                                                    link={
                                                                        extractedData.link
                                                                    }
                                                                />
                                                            </Box>
                                                            {index <
                                                                usersNotifications.length -
                                                                    1 && (
                                                                <Divider />
                                                            )}
                                                        </>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <div>
                                                <Typography>
                                                    You have no new
                                                    Notifications
                                                </Typography>
                                            </div>
                                        )}
                                    </CardContent>
                                </Card>
                            </div>
                        </Fade>
                    ) : null}
                </Box>
            </Box>
        </Fade>
    );
};

export default NotificationsPage;
