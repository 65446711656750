// GameCoverLoading.tsx
import React from 'react';
import { CardContent, Grid, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Game, Upload } from '../../workers/ApiWorker';
import Tag from '../universal/chips/Tag';
import { useNavigate } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import { TextSecondaryColor } from '../../helpers/Themes';
import Card from '@mui/material/Card';
import { Download, Favorite, Save } from '@mui/icons-material';
import { useCustomTheme } from '../../contexts/ThemeContext';

interface GameCoverProps {}

const GameCover: React.FC<GameCoverProps> = ({}) => {
    const { theme } = useCustomTheme();

    return (
        <Skeleton
            variant="rectangular"
            sx={{
                width: '100%',
                maxWidth: 5000, // Optional: Adjust the max width for the card
                backgroundColor: theme.palette.background.paper,
                borderRadius: '4px', // Add border-radius to preserve rounded edges
            }}
        >
            <Card
                sx={{
                    width: '100%',
                    height: '100%', // Make all cards take the same height
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'pointer',
                    transition: 'transform 0.2s',
                    '&:hover': {
                        filter: 'brightness(1.1)',
                        transition: 'all 0.06s ease',
                    },
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        aspectRatio: '3/4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                ></Box>
                {/* Info Section (Game Name) */}
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1, // Ensures consistent height
                        justifyContent: 'space-between',
                        textAlign: 'left',
                        padding: 1, // Adjust padding as needed
                    }}
                >
                    {/* Stats Row */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 1, // Margin-top to separate from game name
                        }}
                    ></Box>
                </CardContent>
            </Card>
        </Skeleton>
    );
};

export default GameCover;
