// src/components/modal/RejectModalUpload.js

import React from 'react';
import { Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import {
    PostUploadApprovalProps,
    Upload,
    UploadApprovalStatusRequest,
} from '../../workers/ApiWorker';
import CustomModal from '../universal/modals/CustomModal';
import ErrorAlert from '../universal/alerts/ErrorAlert';
import TextInput from '../universal/inputs/TextInput';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useApi } from '../../contexts/ApiContext';
import axios from 'axios';
import { unknownError } from '../../helpers/ApiResponses';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import SuccessAlert from '../universal/alerts/SuccessAlert';
import {
    CREATE_REPORT_SUCCESS,
    CREATE_REPORT_FAILED,
} from '../../helpers/Messages';

interface ReportModalUploadProps {
    isMobile: boolean;
    closeModal: (resetForm?: () => void) => void; // Updated to match the definition
    setError: (error: string | undefined) => void; // Updated to match the state setter type
    setSuccess: (success: string | undefined) => void; // Updated to match the state setter type
    isModalOpen: boolean;
    error?: string;
    success?: string;
    upload: Upload;
}

// Define validation schema using Yup
const validationSchemaUpdateUploadStatus = Yup.object({
    reason: Yup.string().required('Reason is required'),
});

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

const ReportModalUpload: React.FC<ReportModalUploadProps> = ({
    isMobile,
    closeModal,
    setError,
    setSuccess,
    isModalOpen,
    error,
    upload,
    success,
}) => {
    const apiWorker = useWorker(createApiWorker);
    const { token } = useApi();
    const { theme } = useCustomTheme();

    return (
        <Formik
            initialValues={{
                reason: '',
            }}
            validationSchema={validationSchemaUpdateUploadStatus}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
                setError(undefined);
                setSuccess(undefined);

                if (!token) {
                    return;
                }

                try {
                    await apiWorker.reportUpload(upload.uuid, token, {
                        reason: values.reason,
                    });

                    setSuccess(CREATE_REPORT_SUCCESS);
                    resetForm();
                } catch (error) {
                    setError(CREATE_REPORT_FAILED);
                }
            }}
        >
            {({
                errors,
                touched,
                values,
                handleChange,
                isSubmitting,
                handleBlur,
                isValid,
                handleSubmit,
                resetForm,
            }) => (
                <CustomModal
                    isOpen={isModalOpen}
                    onClose={() => closeModal(resetForm)}
                    onConfirm={handleSubmit}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    isMobile={isMobile}
                >
                    {/* Modal content passed directly as children */}
                    <Typography
                        variant="h3"
                        sx={{
                            marginBottom: 3,
                        }}
                        color={theme.palette.text.secondary}
                    >
                        REPORT
                    </Typography>
                    {error && <ErrorAlert message={error}></ErrorAlert>}
                    {success && <SuccessAlert message={success}></SuccessAlert>}
                    <TextInput
                        id={'reason'}
                        label={'REASON'}
                        value={values.reason}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasSubmitted={isSubmitting}
                        touched={touched.reason}
                        errors={errors.reason}
                        isMobile={isMobile}
                    />
                </CustomModal>
            )}
        </Formik>
    );
};

export default ReportModalUpload;
