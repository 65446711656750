import TimeAgo from 'javascript-time-ago';

// English.
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

export const addDays = (timestamp: string, no: number): string => {
    const date = new Date(timestamp);
    date.setDate(date.getDate() + no); // Add 'no' days
    return date.toDateString(); // Format as "Day Month Date Year"
};

export const convertSimple = (timestamp: string): string => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'short',
        year: '2-digit',
    };

    return date.toLocaleDateString('en-GB', options);
};

export const convertSimpleWithTime = (timestamp: string): string => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day} ${month} ${year} ${hours}:${minutes}`;
};

export const convertTimeAgo = (timestamp: string): string => {
    const timeAgo = new TimeAgo('en-US');
    return timeAgo.format(new Date(timestamp));
};
