// UserLogin.tsx
import React from 'react';
import TopAppBarItemButtonLink from '../navigation/navbar/TopAppBarItemButtonLink';
import { LoginLink } from '../../helpers/Links';

interface UserLoginProps {
    isMobile: boolean;
    borderLeft?: boolean;
    borderRight?: boolean;
}

const UserLogin: React.FC<UserLoginProps> = ({
    isMobile,
    borderLeft,
    borderRight,
}) => {
    const loginLink = LoginLink(isMobile);

    return (
        <>
            <TopAppBarItemButtonLink
                icon={loginLink.icon}
                text={loginLink.name}
                link={loginLink.path}
                borderLeft={borderLeft}
                borderRight={borderRight}
            />
        </>
    );
};

export default UserLogin;
