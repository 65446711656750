import React, { useEffect, useState } from 'react';
import './App.css';
import TopAppBar from './components/navigation/navbar/TopAppBar';
import { ThemeProvider, CssBaseline, Fade } from '@mui/material';
import Box from '@mui/material/Box';
import SideBarMobile from './components/navigation/sidebar/SideBarMobile';
import AppRoutes from './components/AppRoutes';
import { ApiProvider, CustomJwtPayload } from './contexts/ApiContext';
import { CustomThemeProvider, useCustomTheme } from './contexts/ThemeContext';
import BottomProfileBar from './components/navigation/navbar/BottomProfileBar';
import '@fontsource/archivo';
import '@fontsource/archivo/300.css'; // Light weight
import '@fontsource/archivo/400.css'; // Regular
import '@fontsource/archivo/500.css'; // Medium
import '@fontsource/archivo/600.css'; // Semi-Bold
import '@fontsource/archivo/700.css'; // Bold
import '@fontsource/archivo/800.css'; // Extra Bold
import Footer from './components/footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { fadeTimeout } from './helpers/Themes';
import { User, UserAccountTypes } from './workers/ApiWorker';
import Cookies from 'universal-cookie';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { jwtDecode } from 'jwt-decode';

const createApiWorker = createWorkerFactory(
    () => import('./workers/ApiWorker')
);

function App() {
    const navigate = useNavigate();

    const apiWorker = useWorker(createApiWorker);
    const mobileWidth = 899;
    //const mobileWidth = 1046;
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState<boolean>(width <= mobileWidth);

    const cookies = new Cookies();

    // const [token, setToken] = useState<string | undefined>(cookies.get('token'));

    const [user, setUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        const removeScrollbarPadding = () => {
            // If the window has a vertical scrollbar, reset padding-right
            if (
                document.documentElement.scrollHeight >
                document.documentElement.clientHeight
            ) {
                document.body.style.paddingRight = '0'; // Reset padding-right to 0
            }
        };

        // Call the function when the component mounts
        removeScrollbarPadding();

        // Optional: Check again on window resize to ensure no layout shift
        window.addEventListener('resize', removeScrollbarPadding);

        // Clean up event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', removeScrollbarPadding);
        };
    }, []);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setIsMobile(window.innerWidth <= mobileWidth);
    }

    const location = useLocation(); // Get the current route
    const hideLayout = ['/login', '/register'].includes(location.pathname);

    const locationRequiresToken = [
        '/upload',
        '/admin',
        '/file-upload',
        '/notifications',
    ].includes(location.pathname);

    const locationRequiresNoToken = ['/login', '/register'].includes(
        location.pathname
    );

    const locationRequiresUserFileUpload = ['/file-upload'].includes(
        location.pathname
    );

    const locationRequiresAdminModerator = ['/admin'].includes(
        location.pathname
    );

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    // Calculate padding based on width
    const calculatePadding = () => {
        // Was previously 10
        return 15;
    };

    const padding = calculatePadding(); // Dynamically set padding

    useEffect(() => {
        const forceLogout = async (): Promise<boolean> => {
            cookies.remove('token');
            //setToken(undefined);
            setUser(undefined);
            return true;
        };

        const functionGetMe = async (myToken: string): Promise<User | null> => {
            try {
                const response = await apiWorker.getUserMe(myToken);
                return response.data;
            } catch (error) {
                return null;
            }
        };

        const functionHasMyTokenExpired = async (
            myToken: string
        ): Promise<boolean> => {
            const decodedToken = jwtDecode(myToken) as CustomJwtPayload;
            // We need to check if expired etc
            // Get the current timestamp in seconds
            const currentTimestamp = Math.floor(Date.now() / 1000);

            if (decodedToken.exp) {
                if (decodedToken.exp > currentTimestamp) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };

        const getMyCachedToken = cookies.get('token');
        //setToken(getMyCachedToken);

        // Does this location require a token and there is no token for user
        if (locationRequiresToken && !getMyCachedToken) {
            navigate('/');
        }

        // Does this location require no token and there is a token for the user
        if (locationRequiresNoToken && getMyCachedToken) {
            navigate('/');
        }

        if (!getMyCachedToken && location.pathname === '/suspended') {
            navigate('/');
        }

        if (!getMyCachedToken) {
            // Success (No token)
            setUser(undefined);
        } else {
            // we need to check if the token has expired
            functionHasMyTokenExpired(getMyCachedToken)
                .then((hasExpired) => {
                    if (!hasExpired) {
                        functionGetMe(getMyCachedToken)
                            .then((myUser) => {
                                // if we got a user, check if they are suspended
                                if (myUser !== null) {
                                    if (
                                        myUser.userProfile.accountStatus ===
                                        'suspended'
                                    ) {
                                        if (
                                            location.pathname !== '/suspended'
                                        ) {
                                            navigate('/suspended');
                                        }
                                    }

                                    if (
                                        !myUser.userProfile.canDirectUpload &&
                                        locationRequiresUserFileUpload
                                    ) {
                                        navigate('/');
                                    }

                                    if (
                                        myUser.userProfile.accountType ===
                                            UserAccountTypes.MEMBER &&
                                        locationRequiresAdminModerator
                                    ) {
                                        navigate('/');
                                    }

                                    setUser(myUser);
                                } else {
                                    forceLogout().then((r) => {});
                                    console.log(
                                        'no user redirect to a page with a code'
                                    );
                                }
                            })
                            .catch((error) => {
                                forceLogout().then((r) => {});
                                console.log(
                                    'could not get user, redirect to a page with a code'
                                );
                            });
                    } else {
                        forceLogout().then((r) => {});
                        console.log(
                            'token expired, redirect to a page with a code'
                        );
                    }
                })
                .catch((error) => {
                    forceLogout().then((r) => {});
                    console.log(
                        'Errored checking token, redirect to a page with a code'
                    );
                });
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    // const drawerWidth = 240;
    const drawerWidth = 80;
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);

    const handleMobileDrawerOpen = () => {
        setMobileDrawerOpen(true);
    };

    const handleMobileDrawerClose = () => {
        setMobileDrawerOpen(false);
    };

    return (
        <ApiProvider>
            <CustomThemeProvider>
                <ThemeProvider theme={useCustomTheme().theme}>
                    <Fade in={true} timeout={fadeTimeout}>
                        <div>
                            <div className="App">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minHeight: '100vh',
                                    }}
                                >
                                    <CssBaseline />
                                    {!hideLayout && isMobile && (
                                        <SideBarMobile
                                            drawerOpen={mobileDrawerOpen}
                                            handleDrawerClose={
                                                handleMobileDrawerClose
                                            }
                                            drawerWidth={drawerWidth}
                                            user={user}
                                        />
                                    )}

                                    <Box
                                        component="main"
                                        sx={{
                                            flexGrow: 1,
                                            overflow: 'hidden',
                                            marginTop: 10,
                                        }}
                                    >
                                        {!hideLayout && (
                                            <TopAppBar
                                                isMobile={isMobile}
                                                handleDrawerOpen={
                                                    handleMobileDrawerOpen
                                                }
                                                handleDrawerClose={
                                                    handleMobileDrawerClose
                                                }
                                                drawerOpen={mobileDrawerOpen}
                                                over={false}
                                                user={user}
                                            />
                                        )}
                                        <AppRoutes
                                            isMobile={isMobile}
                                            padding={padding}
                                            user={user}
                                            width={width}
                                        ></AppRoutes>
                                        {!hideLayout && isMobile && (
                                            <BottomProfileBar
                                                isMobile={isMobile}
                                                user={user}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </div>
                            {!hideLayout && !isMobile && <Footer />}

                            {isMobile && hideLayout && (
                                <Box sx={{ marginBottom: 2 }} />
                            )}
                        </div>
                    </Fade>
                </ThemeProvider>
            </CustomThemeProvider>
        </ApiProvider>
    );
}

export default App;
