import React from 'react';
import { alpha, Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import { IconDisabledColor } from '../../../helpers/Themes';
import { NotificationsLink } from '../../../helpers/Links';

interface TopAppBarItemButtonLinkProps {
    icon: JSX.Element;
    text?: string;
    link: string;
    borderLeft?: boolean;
    borderRight?: boolean;
    disabled?: boolean;
}

const TopAppBarItemButtonLink: React.FC<TopAppBarItemButtonLinkProps> = ({
    icon,
    text,
    link,
    borderRight,
    borderLeft,
    disabled,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { theme } = useCustomTheme();
    const notificationsLink = NotificationsLink(false);

    return (
        <Button
            disabled={disabled}
            key={text}
            onClick={() => navigate(link)}
            sx={{
                color:
                    location.pathname === link
                        ? theme.palette.text.primary
                        : theme.palette.text.primary,
                bgcolor:
                    location.pathname !== link ||
                    location.pathname === notificationsLink.path
                        ? 'inherit' // Active button color
                        : theme.palette.primary.main,
                '&:hover': {
                    bgcolor:
                        location.pathname === link
                            ? theme.palette.primary.main // Add slight transparency over active button
                            : alpha(theme.palette.primary.light, 0.15), // Semi-transparent yellow for non-active buttons
                },
                height: `64px`, // Match AppBar height
                paddingRight: 1.5, // Remove extra padding
                paddingLeft: 1.5,
                minWidth: 'auto', // Adjust button width to content
                borderRadius: 0, // Remove rounded borders
                textTransform: 'none', // Disable uppercase text
                borderLeft: borderLeft
                    ? `1px solid ${alpha(theme.palette.primary.light, 0.3)}`
                    : undefined,
                borderRight: borderRight
                    ? `1px solid ${alpha(theme.palette.primary.light, 0.3)}`
                    : undefined,
            }}
        >
            <Box
                sx={{
                    color: !disabled
                        ? theme.palette.text.primary
                        : IconDisabledColor, // Icon color
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: text ? 1 : 0,
                }}
            >
                {React.cloneElement(icon, { fontSize: 'small' })}
            </Box>
            {text ? text.toLowerCase() : null}
        </Button>
    );
};

export default TopAppBarItemButtonLink;
