export const convertYTLink = (url: string): string | undefined => {
    let videoId = '';

    // Check if URL is already in embed format
    if (url.includes('/embed/')) {
        return url;
    }
    // Check if URL is in watch format (https://www.youtube.com/watch?v=...)
    else if (url.includes('/watch?v=')) {
        videoId = url.split('v=')[1].split('&')[0]; // Extract the video ID after "v="
    }
    // Check if URL is in youtu.be format (https://youtu.be/...)
    else if (url.includes('youtu.be/')) {
        videoId = url.split('youtu.be/')[1].split('?')[0]; // Extract the video ID after "youtu.be/"
    }

    // Return the embedded YouTube URL if a valid video ID was found
    return videoId ? `https://www.youtube.com/embed/${videoId}` : undefined;
};

export const getThumbnail = (url: string): string | undefined => {
    let videoId = '';

    // Check if URL is in embed format
    if (url.includes('/embed/')) {
        videoId = url.split('/embed/')[1].split('?')[0];
    }
    // Check if URL is in watch format
    else if (url.includes('/watch?v=')) {
        videoId = url.split('v=')[1].split('&')[0];
    }
    // Check if URL is in youtu.be format
    else if (url.includes('youtu.be/')) {
        videoId = url.split('youtu.be/')[1].split('?')[0];
    }

    // Return the YouTube thumbnail URL if a valid video ID was found
    return videoId
        ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
        : undefined;
};
