import React from 'react';
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { links } from '../../../helpers/Links';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import { useApi } from '../../../contexts/ApiContext';
import { User } from '../../../workers/ApiWorker';

interface SideBarItemsProps {
    handleDrawerClose?: () => void;
    isMobile?: boolean;
    user?: User;
}

const SideBarItems: React.FC<SideBarItemsProps> = ({
    isMobile = false,
    handleDrawerClose,
    user,
}) => {
    const { theme } = useCustomTheme();
    const navigate = useNavigate();
    const location = useLocation(); // React Router hook to get the current location
    const { token } = useApi();

    const sidebarItems = links(isMobile);

    const handleNavigation = (path: string) => {
        if (isMobile) {
            handleDrawerClose!();
        }
        navigate(path);
    };

    return (
        <List>
            {sidebarItems.map((item, index) => {
                if (!item.sideBar) {
                    return null; // Explicitly return null if item.sideBar is false
                }

                if (item.name === 'Upload' && !token) {
                    return null;
                }

                const isActive = location.pathname === item.path;

                return (
                    <ListItem
                        key={item.name}
                        disablePadding
                        onClick={() => handleNavigation(item.path)}
                        sx={{
                            marginBottom: 2,
                            ...(isActive && {
                                backgroundColor: theme.palette.primary.main, // Active background color (primary color)
                            }),
                        }}
                    >
                        <ListItemButton
                            sx={{
                                flexDirection: 'column', // Stack icon and text vertically
                                alignItems: 'center', // Center the content
                                textAlign: 'center',
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    justifyContent: 'center',
                                    color: theme.palette.text.primary,
                                }}
                            >
                                {React.cloneElement(item.icon, { size: 30 })}{' '}
                                {/* Adjust icon size directly */}
                            </ListItemIcon>
                            <ListItemText
                                primary={item.name.toLowerCase()}
                                primaryTypographyProps={{ fontSize: '0.8rem' }}
                                sx={{
                                    color: isActive
                                        ? theme.palette.text.primary
                                        : theme.palette.text.disabled,
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
};

export default SideBarItems;
