import { Notification } from '../workers/ApiWorker';
import en from 'javascript-time-ago/locale/en';

export interface NotificationData {
    additionalContent: string;
    link?: string;
}

export const extractNotificationData = (
    notification: Notification
): NotificationData => {
    let additionalContent: string | undefined;
    let link: string | undefined;

    // Switch based on category to handle different notification types
    switch (notification.category) {
        case 'upload':
            additionalContent = notification.message;

            let enableLink: boolean = true;

            if (notification.data) {
                let notificationData = notification.data;

                if (
                    notification.type === 'upload_approved' ||
                    notification.type === 'upload_reported' ||
                    notification.type === 'report_resolved'
                ) {
                    additionalContent = additionalContent.replace(
                        '%replace%',
                        notificationData.name
                    );
                } else if (notification.type === 'upload_rejected') {
                    additionalContent = additionalContent.replace(
                        '%replace1%',
                        notificationData.name
                    );

                    if (notificationData.declined) {
                        additionalContent = additionalContent.replace(
                            '%replace2%',
                            '<br />' + notificationData.declined.reason
                        );
                    }
                } else if (notification.type === 'upload_deleted') {
                    additionalContent = additionalContent.replace(
                        '%replace1%',
                        notificationData.name
                    );

                    if (notificationData.deleted) {
                        additionalContent = additionalContent.replace(
                            '%replace2%',
                            '<br />' + notificationData.deleted.reason
                        );
                    }

                    enableLink = false;
                } else {
                    additionalContent = additionalContent.replace(
                        '%replace%',
                        notificationData.author.username
                    );
                }

                if (enableLink) {
                    link =
                        notificationData.author.username +
                        '/' +
                        notificationData.uuid;
                }
            }
            break;

        case 'user_profile':
            additionalContent = notification.message;
            if (notification.data) {
                let notificationData = notification.data;
                additionalContent = additionalContent.replace(
                    '%replace%',
                    notificationData.username
                );
                link = notificationData.username;
            }
            break;
    }

    return {
        additionalContent,
        link: link ? '/' + link : undefined,
    };
};
