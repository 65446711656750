// src/components/NotificationBody.tsx

import React from 'react';
import { Button, Typography } from '@mui/material';
import { convertTimeAgo } from '../../helpers/Date';
import { Notification } from '../../workers/ApiWorker';
import Box from '@mui/material/Box';

interface NotificationBodyProps {
    additionalContent: string;
    link?: string;
    createdAt: string;
    isPage?: boolean;
    notification?: Notification;
    handleMarkNotificationAsRead?: (
        notificationUuid: string,
        link?: string
    ) => void;
}

const NotificationBody: React.FC<NotificationBodyProps> = ({
    additionalContent,
    link,
    createdAt,
    isPage,
    notification,
    handleMarkNotificationAsRead,
}) => {
    return (
        <div>
            <Typography
                variant="body1"
                component="div"
                dangerouslySetInnerHTML={{ __html: additionalContent }}
            />
            <Typography variant="caption" color="warning">
                {convertTimeAgo(createdAt)}
            </Typography>
            {isPage && notification && handleMarkNotificationAsRead
                ? (() => {
                      let buttonText: string | undefined = undefined;

                      // Determine the button text based on notification.type
                      switch (notification.category) {
                          case 'upload':
                              buttonText = 'View Upload';
                              break;
                          case 'user_profile':
                              buttonText = 'View User';
                              break;
                      }

                      return (
                          <Box
                              sx={{
                                  marginTop: 3,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: 2,
                              }}
                          >
                              {buttonText ? (
                                  <Button
                                      variant="contained"
                                      color="primary"
                                      type="submit"
                                      onClick={() => {
                                          handleMarkNotificationAsRead(
                                              notification.uuid,
                                              link
                                          );
                                      }}
                                  >
                                      {buttonText}
                                  </Button>
                              ) : null}
                              <Button
                                  variant="contained"
                                  color="error"
                                  type="submit"
                                  onClick={() => {
                                      handleMarkNotificationAsRead(
                                          notification.uuid
                                      );
                                  }}
                              >
                                  Clear Notification
                              </Button>
                          </Box>
                      );
                  })()
                : null}
        </div>
    );
};

export default NotificationBody;
